/**
 * Variables
 */

$break-small: 600px;

// Font weights
$font-weight-light:		100;
$font-weight-regular:	400;
$font-weight-bold:		600;

// Base Font
$base-font-family:	sans-serif;
$base-font-weight:	$font-weight-regular;
$base-font-size:	13px;
$base-line-height:	1.4;


// Fixed Font
$fixed-font-family:	monospace;
$fixed-font-size:	85%;
$fixed-line-height:	$base-line-height;

// Colors
$white: #ffffff;
$blue: #0b619d;

$stone: lighten( #000000, 40 );
$lava: #EC6646;
$smoke: #FAFAFA;

$cherry: #ff0049;
$red: #D0011B;
$mango: #F6A623;

$stone-dark: lighten( #000000, 15 );
$stone-color: $stone;
	$stone-color-mid: lighten( #000000, 60 );
	$stone-color-light: lighten( #000000, 70 );
	$stone-color-lighter: lighten( #000000, 80 );
	$stone-color-lightest: lighten( #000000, 90 );

$primary-color: $stone;
	$primary-color-dark: mix($primary-color,black,80%);
	$primary-color-light: mix($primary-color,white,80%);
	$primary-color-lighter: mix($primary-color,white,40%);
	$primary-color-lightest: mix($primary-color,white,20%);


$secondary-color: $lava;
	$secondary-color-dark: mix($secondary-color,black,80%);
	$secondary-color-light: mix($secondary-color,white,80%);
	$secondary-color-lighter: mix($secondary-color,white,40%);
	$secondary-color-lightest: mix($secondary-color,white,20%);

$accent-color: $smoke;
	$accent-color-dark: mix($accent-color,black,95%);
	$accent-color-light: mix($accent-color,white,0%);
	$accent-color-lighter: mix($accent-color,black,60%);
	$accent-color-lightest: mix($accent-color,black,40%);


$stepbar__text--active: $primary-color;
$stepbar__text--inactive: $stone-color-light;
$stepbar__item__bg: $white;
$stepbar__item__bg--active: $stone-color-lightest;
$stepbar__item__border: $stone-color-lighter;


$messagebar__text: $stone-color;
$messagebar__bg: $primary-color-lightest;
$messagebar__bg--alert: $cherry;

$notifybar__bg: $white;
$notifybar__text: $mango;

$mainmenu__item__bg: $white;
$mainmenu__item__bg--hover: $stone-color-lightest;
$mainmenu__item__bg--active: $primary-color-lightest;
$mainmenu__item__text: $stone-color;
$mainmenu__item__text--active: $primary-color;

$agenda__header_bg: $primary-color;
$agenda__header_txt: $white;
