// @import '_utils/reset';
@import '_utils/mixins';

@import 'variables';

@import '_layout/grid';
@import '_layout/typography';
// @import '_layout/utils';

// @import '_utils/animations';

// .font01 {font-family: 'Roboto', sans-serif !important;}
// .font02 {font-family: 'Roboto Condensed', sans-serif !important;}
// .font03 {font-family: 'Saira Semi Condensed', sans-serif !important;}
// .font04 {font-family: 'Montserrat', sans-serif !important;}
// .font05 {font-family: 'Slabo 27px', serif !important;}
// .font06 {font-family: 'Oswald', sans-serif !important;}
// .font07 {font-family: 'Open Sans Condensed', sans-serif !important;}
// .font08 {font-family: 'Droid Serif', serif !important;}
// .font09 {font-family: 'Saira', sans-serif !important;}

@font-face {
    font-family: 'Swift';
    src: url('swift/assets/font/swift-light-webfont.eot');
    src: url('swift/assets/font/swift-light-webfont.eot?#iefix') format('embedded-opentype'),
         url('swift/assets/font/swift-light-webfont.woff2') format('woff2'),
         url('swift/assets/font/swift-light-webfont.woff') format('woff'),
         url('swift/assets/font/swift-light-webfont.ttf') format('truetype'),
         url('swift/assets/font/swift-light-webfont.svg#swiftlight') format('svg');
    font-weight: normal;
    font-style: normal;
}

.font--swift {
	font-family: 'Swift';
	font-size: 40vh;

	@media only screen and (max-width: $break-small) {
		font-size: 70vw;
	}
}

* {
	box-sizing: border-box;
}


body {
	margin: 0;
	padding: 0;
	height: 100%;
	min-width: 800px;
	min-height: 600px;
	font-size: 24px;
	font-family: 'proxima-nova', 'Montserrat', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	overflow: hidden;

	@media screen and (max-width: $break-small) {
		font-size: 18px;
		min-height: initial;
		min-width: initial;
	}

	background: $stone-dark;
	color: $lava;

	text-align: center;
}

#JMcredits {
	user-select: none;
	position: absolute !important;
	mix-blend-mode: exclusion !important;
	bottom: 15vw !important;
	left: 50% !important;
	transform: translateX(-50%) !important;
}


	.scroll-downs {
		transform: translateZ(0);
		position: absolute;
		right: 0;
		bottom: 5vh;
		left: 0;
		margin: auto;
		width: 4vh;
		height: 6vh;

		mix-blend-mode: difference;

		@media only screen and (max-width: $break-small) {
			display: none;
		}

		.mousey {
			width: 100%;
			height: 100%;
			padding: 0.7vh;
			border: 2px solid #fff;
			border-radius: 25px;
			opacity: 0.75;
			box-sizing: border-box;

			.scroller {
				width: 2px;
				margin: 0 auto;
				height: 1.5vh;
				border-radius: 25%;
				background-color: #fff;
				animation-name: scroll;
				animation-duration: 2.2s;
				animation-timing-function: cubic-bezier(.15,.41,.69,.94);
				animation-iteration-count: infinite;
			}
		}
	}

	@keyframes scroll {
		0% { opacity: 0; }
		10% { transform: translateY(0); opacity: 1; }
		100% { transform: translateY(15px); opacity: 0;}
	}

#content {
	position: relative;
	display: block;
	width: 100%;
	//background: white;
	//box-shadow: 0 1vh 5vh 0 rgb(0, 0, 0), 0 5vh 20vh 0 rgba(0, 0, 0, 0.5);

	.container {
		display: block;
		min-width: 100vw;
		min-height: 50vh;
		color: white;
		overflow: hidden;
		position:relative;

		background-size: cover;
		background-position: center center;

		a {
			position: absolute;
			display: block;
			width: 100%;
			height: 100%;
			background: rgba(black,0.7);
			transition: all 160ms ease-out;

			&:hover {
				background: rgba(black,0);
				transition: all 160ms ease-out;
			}

			span {
				position: absolute;
				display: block;
				top: 50%;
				left: 50%;
				transform: translateY(-50%) translateX(-50%);
				color: white;
			}
		}

		&.font--swift {
			background-image: url(../../swift/assets/img/bg_01.jpg);
		}
	}
}

#background {
	display: block;
	position: relative;
	width: 100vw;
	height: 100vh;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 0;

	.artistCover {

		position: absolute;
		width: 100vw;
		height: 100vh;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;

		transform: translateZ(0);

		background-size: cover;
		background-position: center center;

		opacity:0;
		transition: opacity 450ms linear;

		&.active{
			opacity:1;
			z-index:10;
			transition: opacity 450ms linear;
		}


		video {
			position: fixed;
			top: 50%;
			left: 50%;
			min-width: 100%;
			min-height: 100%;
			width: auto;
			height: auto;
			transform: translateX(-50%) translateY(-50%);
		}
	}
}

.heroText {
	display: table;
	table-layout: fixed;
	width: 85vw;
	// height: calc( 100vh - 15vw );
	font-size: 5vw;
	text-align: center;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translateY(-50%) translateX(-50%);
	margin: 0 auto;
	padding: 0;
	color: white;
	mix-blend-mode: exclusion;

	user-select: none;

	@media only screen and (max-width: $break-small) {
		top: 50%;
	}

	h1 {
		display: block;
		margin: 0 auto;
		font-size: 1vw;
		width: 80em;
		height: 20em;
		text-indent: -500vw;

		background-image: url(../img/stereotype.png);
		background-size: 100% 1000%;

		animation: play 1s steps(10) infinite;
	}

	@keyframes play {
		0% { background-position: 0 0; }
		100% { background-position: 0 1000%; }
	}

	//background: rgba(0, 203, 255, 0.38);

	//@include linear-gradient(rgba(33, 150, 243, 0.51), rgba(255, 0, 0, 0.26));

	.wrapper {
		width: 90%;
		margin: 4% auto;
	}

	.letter {
		display: table-cell;
		vertical-align: middle;
		font-weight: 100;
		width: 10%;
		overflow: hidden;
		height: 100%;
		position: relative;
		font-weight:100;

		&:nth-of-type(8),
		&:nth-of-type(9),
		&:nth-of-type(10),
		&:nth-of-type(11) {
			//width: 10%;
			font-size: 180%;
			overflow: hidden;
			font-weight: 900;
		}

		span {
			//position: absolute;
			vertical-align: middle;
			//top: 40%;
			left: 0;
			//display: block;
			line-height: 1;
			width: 100%;
			height: 100%;



			//animation: letterFade 2s infinite;

			&:first-child {
				// color: inherit;
				// -webkit-text-fill-color: transparent;
				// background: -webkit-linear-gradient(rgba(236, 102, 70, 0.5), rgba(236, 102, 70, 0.5)), url(../img/lava.gif) repeat;
				// background-size: cover;
				// background-attachment: fixed;
				// background: -o-linear-gradient(transparent, transparent);
				// -webkit-background-clip: text !important;
			}
			&:last-child {
				position: absolute;
				opacity: 0.2;
				font-size: 110%;
				//filter: blur(4px);
			}

			&.animated {
				animation: letterFade 1s infinite;
			}

		}
	}
}

@keyframes letterFade {
	0% {opacity: 0;transform:translateY(10vh) translateZ(0);}
	50% {opacity: 0.3;transform:translateY(0)}
	100% {opacity: 0;transform:translateY(-10vh)}
}

.lavaText {
	color: inherit;
	-webkit-text-fill-color: transparent;
	background: -webkit-linear-gradient(rgba(236, 102, 70, 0.5), rgba(236, 102, 70, 0.5)), url(../img/lava.gif) repeat;
	background-size: cover;
	background-attachment: fixed;
	background: -o-linear-gradient(transparent, transparent);
	-webkit-background-clip: text !important;
}