/***********************************
	MIXINS -- start
*/
@function blend-normal ($foreground, $background) {
  $opacity: opacity($foreground);
  $background-opacity: opacity($background);

  // calculate opacity
  $bm-red: red($foreground) * $opacity + red($background) * $background-opacity * (1 - $opacity);
  $bm-green: green($foreground) * $opacity + green($background) * $background-opacity * (1 - $opacity);
  $bm-blue: blue($foreground) * $opacity + blue($background) * $background-opacity * (1 - $opacity);
  @return rgb($bm-red, $bm-green, $bm-blue);
}
//--------------------------------
// Multiply
//--------------------------------
@function blend-multiply ($foreground, $background) {
  $bm-red: red($background) * red($foreground) / 255;
  $bm-green: green($background) * green($foreground) / 255;
  $bm-blue: blue($background) * blue($foreground) / 255;

  @return blend-normal(rgba($bm-red, $bm-green, $bm-blue, opacity($foreground)), $background);
}

/***********************************
	BEM MIXIN - MODULES & MODIFIER

	Sass (v3.4.0)
	Compass (v1.0.0)
***********************************/

$elementSeparator: '__';
$modifierSeparator: '--';

@function containsModifier($selector) {
	$selector: selectorToString($selector);
	@if str-index($selector, $modifierSeparator) {
		@return true;
	} @else {
		@return false;
	}
}

@function selectorToString($selector) {
	$selector: inspect($selector); //cast to string
	$selector: str-slice($selector, 2, -2); //remove brackets
	@return $selector;
}

@function getBlock($selector) {
	$selector: selectorToString($selector);
	$modifierStart: str-index($selector, $modifierSeparator) - 1;
	@return str-slice($selector, 0, $modifierStart);
}

@mixin b($block) {
	.#{$block} {
		@content;
	}
}

@mixin e($element) {
	$selector: &;
	@if containsModifier($selector) {
		$block: getBlock($selector);
		@at-root {
			#{$selector} {
				#{$block+$elementSeparator+$element} {
					@content;
				}
			}
		}
	} @else {
		@at-root {
			#{$selector+$elementSeparator+$element} {
				@content;
			}
		}
	}
}

@mixin m($modifier) {
	@at-root {
		#{&}#{$modifierSeparator+$modifier} {
			@content;
		}
	}
}

/*	USAGE
-----------------------------------
	@include b(test) {
		background: red;
		@include e(element){
			font-size: 14px;

			@include m(big) {
				font-size: 18px;
			}
		};
		@include m(modifier) {
			color: blue;

			@include e(subelement) {
				background: gray;
			}
		}
	}
*/


/***********************************
	TRANSITION
***********************************/
@mixin transition($args...) {
	-webkit-transition: $args;
	   -moz-transition: $args;
		-ms-transition: $args;
		 -o-transition: $args;
			transition: $args;
}

/*	USAGE
-----------------------------------

	@include transition(color .3s ease);

*/


/***********************************
	CROSS BROSER OPACITY
***********************************/

@mixin opacity($opacity) {
	opacity: $opacity;
	$opacity-ie: $opacity * 100;
	filter: alpha(opacity=$opacity-ie); //IE8
}

/*	USAGE
-----------------------------------

	@include opacity(0.8);

*/



/***********************************
	CLEARFIX
***********************************/
%clearfix {
	*zoom: 1;
		&:before, &:after {
		content: " ";
		display: table;
	}
	&:after {
		clear: both;
	}
}

/*	USAGE
-----------------------------------

	@extend %clearfix;

*/


/***********************************
	PLACEHOLDER
***********************************/
@mixin input-placeholder {
	&.placeholder { @content; }
	&:-moz-placeholder { @content; }
	&::-moz-placeholder { @content; }
	&:-ms-input-placeholder { @content; }
	&::-webkit-input-placeholder { @content; }
}

/*	USAGE
-----------------------------------

	@include input-placeholder {
		color: $grey;
	}

*/


/// Convert angle
/// @author Chris Eppstein
/// @param {Number} $value - Value to convert
/// @param {String} $unit - Unit to convert to
/// @return {Number} Converted angle
@function convert-angle($value, $unit) {
  $convertable-units: deg grad turn rad;
  $conversion-factors: 1 (10grad/9deg) (1turn/360deg) (3.1415926rad/180deg);
  @if index($convertable-units, unit($value)) and index($convertable-units, $unit) {
    @return $value
             / nth($conversion-factors, index($convertable-units, unit($value)))
             * nth($conversion-factors, index($convertable-units, $unit));
  }
  
  @warn "Cannot convert `#{unit($value)}` to `#{$unit}`.";
}

/// Test if `$value` is an angle
/// @param {*} $value - Value to test
/// @return {Bool}
@function is-direction($value) {
  $is-direction: index((to top, to top right, to right top, to right, to bottom right, to right bottom, to bottom, to bottom left, to left bottom, to left, to left top, to top left), $value);
  $is-angle: type-of($value) == 'number' and index('deg' 'grad' 'turn' 'rad', unit($value));
  
  @return $is-direction or $is-angle;
}

/// Convert a direction to legacy syntax
/// @param {Keyword | Angle} $value - Value to convert
/// @require {function} is-direction
/// @require {function} convert-angle
@function legacy-direction($value) {
  @if is-direction($value) == false {
    @warn "Cannot convert `#{$value}` to legacy syntax because it doesn't seem to be an angle or a direction";
  }
  
  $conversion-map: ( 
    to top          : bottom,
    to top right    : bottom left,
    to right top    : left bottom,
    to right        : left,
    to bottom right : top left,
    to right bottom : left top,
    to bottom       : top,
    to bottom left  : top right,
    to left bottom  : right top,
    to left         : right,
    to left top     : right bottom,
    to top left     : bottom right
  );
    
  @if map-has-key($conversion-map, $value) {
    @return map-get($conversion-map, $value);
  }
  
  @return 90deg - convert-angle($value, 'deg');
}

/// Mixin printing a linear-gradient 
/// as well as a plain color fallback 
/// and the `-webkit-` prefixed declaration
/// @access public
/// @param {String | List | Angle} $direction - Linear gradient direction
/// @param {Arglist} $color-stops - List of color-stops composing the gradient
@mixin linear-gradient($direction, $color-stops...) {
  @if is-direction($direction) == false {
    $color-stops: ($direction, $color-stops);
    $direction: 180deg;
  }
  
  background: nth(nth($color-stops, 1), 1);
  background: -webkit-linear-gradient(legacy-direction($direction), $color-stops);
  background: linear-gradient($direction, $color-stops);
}

// // Tests
// .test-1 {
//   @include linear-gradient(#31B7D7, #EDAC7D);
// }

// .test-2 {
//   @include linear-gradient(to right, #E47D7D 0%, #C195D3 50%, #4FB4E8 100%);
// }

// .test-3 {
//   @include linear-gradient(42deg, #B58234 0%, #D2B545 50%, #D7C04D 50.01%, #FFFFFF 100%);
// }





/*
	MIXINS -- end
***********************************/
